let db,
	localLoaded = new Map,
	notfound = new Set,
	request = indexedDB.open( "lang", 1 ),
	someNotFound;

window.languageEmo = {
	en: '🌐', uk: '🇺🇦', de: '🇩🇪', fr: '🇫🇷', lt: '🇱🇹', pl: '🇵🇱', tr: '🇹🇷', bg: '🇧🇬', cs: '🇨🇿',
	da: '🇩🇰', el: '🇬🇷', es: '🇪🇸', fi: '🇫🇮', et: '🇪🇪', hr: '🇭🇷', hu: '🇭🇺', it: '🇮🇹', nl: '🇳🇱',
	sv: '🇸🇪', sk: '🇸🇰', ru: '🇷🇺', lv: '🇱🇻', no: '🇳🇴', ro: '🇷🇴', sl: '🇸🇮', sq: '🇦🇱', pt: '🇵🇹'
};

if( !window.GAMBLERRU ) languageEmo.ru = '🏴';

window.allLanguages = new Set(
	( window.GAMBLERRU?
		'ru en by uk kz' :
		'ru en lt uk pl bg cs da de el es et fi fr hr hu it lv nl no pt ro sk sl sq sv tr' )
		.split( ' ' ) );

request.onsuccess = () => {
	db = request.result;
	checkLoadLocal();
}

request.onupgradeneeded = e => {
	let d = e.target.result;

	d.onerror = () => {
		window.log?.( "Error creating langs" );
	};
	if( e.oldVersion>0 ) {
		try {
			d.deleteObjectStore( "phrases" );
		} catch( e ) {
		}
	}
	try {
		let phrases = d.createObjectStore( "phrases", { keyPath: ['id', 'lang'] } );
		phrases.createIndex( 'lang', 'lang' );
		if( LOCALTEST ) setTimeout( () => {
			d.transaction( 'phrases', 'readwrite' )
				.objectStore( 'phrases' )
				.put( {
					lang: 'ru',
					id: 'test',
					phrase: 'тест'
				} );
		}, 1000 );
	} catch( err ) {
		window.log && log( 'Error while upgrading lang db ' + JSON.stringify( err ) );
	}
}

/* Detect language or get it from browser */
(function() {
	let langs = new Set;
	let start = localStorage['language'],
		lang = navigator.language.split( '-' )[0];
	// Отключим русский по-умолчанию на playelephant.com
	if( location.href.includes( 'gambler.ru' ) && navigator.languages.find( x => x.includes( 'ru' ) ) ) lang = 'ru';
	// if( location.href.includes( 'playelephant' ) && lang==='ru' ) lang = 'en';
	let all = navigator.languages;
	langs.add( lang );
	if( all ) {
		for( let i = 0; i<all.length; i++ ) {
			let l = all[i].split( '-' )[0];
			langs.add( l );
		}
	}
	if( start ) langs.add( start );
	langs.add( 'en' );
	if( !start ) {
		for( let l of langs ) {
			start = l;
			if( allLanguages.has( start ) ) break;
		}
	}
	window.myLanguages = langs;
	window.startLanguage = start;
})();

window.needTranslate = new Set;

let Phrases = null, //new Map(),
	loadedfromdb = null,
	unknownPhrases = new Set,
	allTranslate = new Set,
	langMap = {},
	myLanguage = {
		language: null,
		getPhrase: str => Phrases?.get( str ) || str,
		hasPhrase: str => Phrases?.has( str )
	};

window.elephLang = myLanguage;
export default myLanguage;

//---- Support functions
// Подготовим текущий документ к локализации. Далее по мере добавления будем "локализовать на лету"
function retranslate() {
	for( let o of needTranslate ) {
		prepareNode( o );
	}
}

function prepareNode( text ) {
	if( text.tagName==='STYLE' || text.tagName==='SCRIPT' ) return;
	if( text.children.length ) {
		// Обработаем каждого ребенка по отдельности
		for( let ch of text.children )
			prepareNode( ch );
		return;
	}
	let translated = false;
	if( text.langText ) {
		let res = translate( text.langText );
		translated = res!==text.langText;
		if( res.match( /<img|<\/|<BR>/i ) )
			text.innerHTML = res;
		else
			text.textContent = res;
	}
	if( text.langPlaceholder ) {
		let res = translate( text.langPlaceholder );
		translated = res!==text.langText;
		text.placeholder = res;
	}
	if( translated ) {
		// if( !res.match( /[\{\}]/ ) ) {
			text.classList.remove( 'untranslated' );
			needTranslate.delete( text );
		// }
	}
}

function isUpperCase( str ) {
	if( str[0]==='_' ) return false;
	for( let c of str ) if( c>='a' && c<='z' ) return false;
	return true;
}

let toloadset;

function result( res, params ) {
	if( typeof res==='string' ) return res;
	// 1. Сначала надо заменить $0 ... $n на параметры
	if( res.nums ) {
		// Phrase - numerator. Find by wildcard
		let def;
		for( let x of res.nums ) {
			if( params[0].match( x.pattern ) ) return x.phrase;
			if( !def || x.default ) def = x.phrase;
		}
		return def || '';
	}
	let text = res.text.replace( /\$(\d)/g, ( match, p1 ) => {
		try {
			return decodeURIComponent( params[p1] || '' ) || '';
		} catch( e ) {
			log( 'bad URI ' + params[p1] );
			return params[p1];
		}
	})

	// Подставим объекты
	function subst( s, body ) {
		let ar = body.split( '.' ),
			origin = ar[0],
			object = User.get( origin );
		if( !object ) {
			let o = { origin: origin };
			fire( 'resolveorigin', o );
			object = o.object;
		}
		if( !object ) {
			return body;
		}
		let field = ar[1]||'name',
			value = ar[1] && object[ar[1]] || object.getShowName;
		return `<span class='control blue' data-origin='${object.itemid}' data-field='${field}' 
			data-name='${object.itemid}.${field}'>${value}</span>`;
	}
	let str = text.replace( /\[([a-zA-Z_\.\d]+)\]/g, subst );
	return str;
}

function translate( str, options ) {
	someNotFound = false;
	if( !str ) return '';
	// if( +str ) return str;
	if( Phrases?.size ) {
		function replacer( s, body ) {
			if( body==='DOMAIN' ) return DOMAIN;
			if( body[0]==='!' && options?.game ) {
				let gar = body.slice( 1 ).split( '.' );
				if( gar[0]==='plr' ) {
					let place = +gar[1];
					let user = options.game.getUser( place );
					return user?.getShowName || Phrases.get( 'player' ) + ' ' + place;
				}
			}

			if( body[0]==='$' ) {
				let num = body.slice( 1 );
				return `<span class='fants'>${num}</span>`;
			}

			let skipnotfound;
			if( body[0]==='?' ) {
				// Hide this phrase if not found
				skipnotfound = true;
				body = body.slice( 1 );
			}
			let params = body.split( /[\,\:]/ )
			body = params[0];
			params.splice( 0,1 );
			// Prepare format {phrase,param,param...}
			let res = Phrases.get( body );
			if( res ) return result( res, params );
			let sp = body.toLowerCase();
			let numcheck = sp.match( /(\w*)_num:(\d+)/ );
			if( numcheck ) {
				sp = numcheck[1];
				// if( sp.startsWith( 'month_num' ) ) sp = 'month'
				// if( sp.startsWith( 'year_num' ) ) sp = 'year';
			}
			res = Phrases.get( sp );
			if( !res ) {
				// Если фраза состоит из частей prefix-postfix, и она не найдена,
				// то сначала пробуем найти prefix_postfix, затем postfix
				if( sp.includes( '-' ) ) {
					let ar = sp.split( '-' );
					res = Phrases.get( ar.slice( -1 ) );
					if( res ) return res;
					sp = sp.replace( '-', '_' );
				}
				sp = sp.replace( /[_#\+]/g, '_' );
				res = Phrases.get( sp );
				if( !res ) {
					// Remove trailing underscore. "Problem {_Pok_texas_}"
					if( sp.slice( -1 )==='_' ) res = Phrases.get( sp.slice( 0, -1 ) );
				}
				// 1/12/23 canceled mode of 'Some_words_translated_separately'
				// res ||= sp.split( '_' ).map( x => x && ( Phrases.get( x ) || '**!!**' ) || '' ).join( ' ' );
				// if( res.includes( '**!!**' ) ) res = null;
				/*			}

							else if( sp.includes( '#' ) ) {
								res = Phrases.get( sp.replace( '#', '_' ) )
									|| sp.split( '#' ).map( x => Phrases.get( x ) || x ).join( ' ' );
							}
				*/
			}
			if( !res ) {
				// Фраза не найдена
				if( !options?.norequest && !unknownPhrases.has( [language, sp] ) ) {
					if( LOCALTEST && +body ) debugger;
					unknownPhrases.add( [language, body] );
					needLoad( body );
					someNotFound = true;
					// if( !LOCALTEST )
					/*
										API( '/unknownphrase', {
											l: myLanguage.language,
											phrase: sp
										} );
					*/
				}
				return skipnotfound && '' || s;
			}
			// Result found, check capitalize
			let first = body[0]==='_' && body[1] || body[0];
			if( first>='A' && first<='Z' ) {
				if( typeof res==='string' ) 
					res = isUpperCase( body )? res.toUpperCase() : res.capitalize();
				Phrases.set( body, res );
			}
			return result( res, params );
		}

		str = str.replace( /\{(.*?)\}/g, replacer );
	}

	// Possibility to use of object parametr

	let notranslit = /*window.FANTGAMES || window.NEOBRIDGE ||*/ options?.notranslit || false;
	if( window.EXTERNALDOMAIN ) notranslit = true;
	return notranslit && str || needTransliterate( str );
}

function setPhrase( id, ph, map ) {
	let ch = /\[[a-zA-Z_\$\.\$\d]+\]|\$\d/.exec( ph ),
		dest = map || Phrases;
	if( ch )
		dest.set( id, { text: ph, exp: ch } );
	else {
		if( LOCALTEST && id==='month_num' ) ph = '{ *11,*12,*13,*14,*: месяцев; *1: месяц; *2,*3,*4: месяца }';
		let nums = ph.startsWith( '{ ' ) && ph.matchAll( /\s(.*?):\s([^\s;]*)[;\s]/g );
		if( nums ) {
			nums = [...nums];
			let data = nums.map( x => { return {
				pattern: new RegExp( x[1].split( ',' )
					.map( x => x==='*'? '' : `(${x.replace( '*', '\\d?' )})` )
					.filter( x => !!x )
					.join( '|' ) + '$' ),
				default: x[1].match( /\*[:,]/ ),
				phrase: x[2]
			} } );
			dest.set( id, { text: ph, nums: data } );
		} else
			dest.set( id, ph );
	}
}

let waitingloadunknown;
function needLoadUnknown() {
	if( waitingloadunknown ) return;
	waitingloadunknown = setTimeout( loadunknown, 500 );
}

function storePhrase( k, ph, lang ) {
	db?.transaction( 'phrases', 'readwrite' )
		.objectStore( 'phrases' )
		.put( {
			lang: lang,
			id: k,
			phrase: ph,
			time: Math.floor( Date.now()/1000 )
		} );
}
async function loadunknown() {
	waitingloadunknown = null;
	if( !toloadset || !toloadset.size ) return;
	// Запросим phrases по-старому и updatephrases - список объектов, с указанием времени
	let cre = [];
	for( let o of toloadset ) {
		if( typeof o==='string' )
			cre.push( o );
		else
			cre.push( { id: o.id, time: o.time } );
	}
	let res = await API( '/loadphrases', {
		language: myLanguage.language,
		phrases: cre,
	}, 'internal' );
	toloadset.clear();
	if( !res?.ok ) return;
	if( res.notfound ) {
		for( let k of res.notfound ) {
			log( 'lang: deleting phrase ' + k );
			db?.transaction( 'phrases', 'readwrite' )
				.objectStore( 'phrases' )
				.delete( [k, language] );
			// Keeping in notfound local index
			notfound.add( k );
		}
	}
	// У немодифицированных обновим дату в локальной базе
	if( Phrases && Array.isArray( res.notmodified ) )
		for( let k in res.notmodified ) {
			storePhrase( k, Phrases[k], language );
		}
	// Новозагруженные фразы
	for( let k in res.phrases ) {
		let ph = res.phrases[k],  //.replace( '\n', '<br>' );
			old = Phrases.get( k );
		// if( JSON.stringify(ph)===JSON.stringify(old) ) continue;
		setPhrase( k, ph );
		storePhrase( k, ph, language );
	}
	// Переводим пока всё. TODO: переводить надо только те объекты, где была загрузка
	retranslate();
}

function tolocal( el, field, pfield ) {
	let s = el.dataset[pfield];
	if( !s ) return;
	let t = translate( s );
	if( t===s ) {
		el.removeAttribute( 'data-' + pfield );
	} else {
		el[field] = t;
	}
}

// ------ Export
let waitingLang = new Set;

window.waitLanguage = () => {
	if( Phrases?.size ) return;
	return new Promise( resolve => {
		waitingLang.add( resolve );
	} )
}

myLanguage.setLanguage = async ( language, onload ) => {
	if( !language || language===myLanguage.language ) return;
	if( !allLanguages.has( language ) ) return;
	myLanguage.language = language;
	window.language = language;
	window.needTransliteration = !'ru uk be kz ka hy uz'.includes( language );
	if( !onload )
		localStorage.language = language;
	// Пробуем переключение языка перезагрузкой страницы
	// Запрос на изменение языка у пользователя

	if( onload ) document.documentElement.setAttribute( 'lang', language );
	if( !onload && window.UIN ) API( '/setlanguage', { language: language } );

	if( Phrases ) {
		sessionStorage.lang_dorefresh = 1;
		location.reload();
		return;
	}
	let module;
	try {
		module = await import( './lang/' + language + '_module.js' );
	} catch( e ) {
		if( document.currentScript )
			module = await import( `${document.currentScript.src.replace( /\/[^\/]*$/, '/lang/ ' + language + '_module.js' )}` );
	}
	if( !module ) return;
	if( !Phrases ) {
		// При первой загрузке языка рассылаем сообщение
		setTimeout( () => document.dispatchEvent( new CustomEvent( 'languageload' ) ), 200 );
	}
	Phrases = langMap[language];
	if( !Phrases ) {
		Phrases = langMap[language] = new Map( module.default );

		for( let [k, v] of Phrases ) {
			if( k[0]>='A' && k[0]<='Z' ) {
				let sl = k.toLowerCase();
				if( !Phrases.has( sl ) ) setPhrase( sl, v.toLowerCase() );
			} else if( k[0]>='a' && k[0]<='z' ) {
				let sl = k[0].toUpperCase() + k.slice( 1 );
				try {
					if( !Phrases.has( sl ) ) setPhrase( sl, v.length && (v[0].toUpperCase() + v.slice( 1 )) || '' );
				} catch( e ) {
					bugReport( 'myLanguage failed ' + language );
				}
			}
		}
		let localmap = localLoaded.get( language );
		if( localmap ) Phrases = new Map( [...localmap, ...Phrases] );
	}

	// Закроем ожидающие промисы
	for( let resolve of waitingLang ) resolve();
	waitingLang.clear();

	// Если из локальной базы еще не грузили, загрузим
	await checkLoadLocal();

	// Обработаем то, что уже загрузилось
	retranslate();

	let echo = document.querySelectorAll( '[data-language="current"]' );
	for( let k = echo.length; k--; )
		echo[k].setContent( language );
	// echo[k].setContent( '{langname}' );

	if( !onload ) toast( translate( '{langname}' ) );

	window.LANGUAGELOADED = true;
};

function checkLoadLocal() {
	if( !db || !myLanguage.language || localLoaded.has( myLanguage.language ) ) return;
	let tr,
		refresh = sessionStorage.lang_dorefresh;
	return new Promise( resolve => {
		try {
			tr = db.transaction( 'phrases', 'readonly' ).objectStore( 'phrases' ).index( 'lang' );
		} catch( e ) {
		}
		let map = new Map;
		localLoaded.set( myLanguage.language, map );
		if( tr.getAll ) tr.getAll( myLanguage.language ).onsuccess = e => {
			for( let o of e.target.result ) {
				if( !o.phrase ) {
					// Wrong phrase, need to delete
					continue;
				}
				setPhrase( o.id, o.phrase, map );
				if( o.time > Date.now()/1000 ) o.time = Math.floor( o.time / 1000 );
				// Если фразе больше месяца, обновим
				if( refresh || o.time<Date.now() - 60 * 60 * 24 * 30 * 1000 || LOCALTEST ) needLoad( o );
			}
			if( Phrases ) Phrases = new Map( [...map, ...Phrases] );
		}
		resolve();
	} );
}

myLanguage.shortPhrase = str => {
	if( Phrases.get( str + '_' ) ) return '{' + str + '_}';
	if( Phrases.has( str ) ) return '{' + str + '}';
	return str;
};

function setTitle( el, ttl ) {
	let t = translate( ttl );
	if( !Phrases || t!==ttl ) {
		el.dataset['ttl_phrase'] = ttl;
	}
	el.title = t;
}

myLanguage.translate = translate;
window.localize = translate;

HTMLElement.prototype.html = function( value ) {
	this.setContent( value, null, 'html' );
	return this;
};

function markTranslate( el ) {
	if( el.children.length ) {
		// Обработаем каждого ребенка по отдельности
		for( let ch of el.children )
			if( ch.tagName!=='STYLE' ) markTranslate( ch );
		return;
	}
	let text = el.textContent;
	if( text.match( /[\{\}]/ ) ) {
		needTranslate.add( el );
		allTranslate.add( el );
		el.langText = text;
		// el.textContent = ' '.repeat( text.length );
		el.classList.add( 'untranslated')
	}
	if( el.placeholder?.match( /[\{\}]/ )) {
		needTranslate.add( el );
		allTranslate.add( el );
		el.langPlaceholder = el.placeholder;
	}
}

HTMLElement.prototype.langInsertAdjacentHTML = function( position, str ) {
	let s = translate( str );
	this.insertAdjacentHTML( position, s );
	if( someNotFound ) markTranslate( this );
}

HTMLElement.prototype.setContent = async function( text, game, options ) {
	if( typeof text==='number' ) text = text.toString();
	if( typeof text!=='string' ) text = '';
	if( text?.[0]==='@' ) {
		this.title = translate( text.slice( 1 ) );
		return;
	}
	let oldsize = toloadset?.size || 0,
		needtranslate = false,
		t = translate( text, { game: game } );
	// Добавляем объект в переводимый, если слова еще не загружены,
	// или фраза потребовала перевода (для дальнейшего переключения языков)
	if( !Phrases || t!==text || someNotFound || toloadset?.size>oldsize ) {
		// this.dataset['tphrase'] = text;
		// needTranslate.add( this ); //, text );
		needtranslate = true;
		// this.dataset.needtranslate = 1;
	}
	// else {
	// 	this.removeAttribute( 'data-tphrase' );
	// }
	if( options==='html' || t.includes( '&' ) || t.match( /<img|<\/|<BR>/i ) )
		this.innerHTML = t;
	else
		this[this.tagName==='INPUT' ? 'value' : 'textContent'] = t;

	if( needtranslate ) {
		// Mark all neccesary objects
		markTranslate( this.content || this );
		// log( 'To translate: ' + ( this.className || this.content?.className || '??' ) );
	}
};

// Base event handler (keyboard switcher)
window.addEventListener( 'keydown', function( e ) {
	if( e.ctrlKey && (e.key==='l' || e.keyCode===76) ) {
		let ar = [...allLanguages];
		myLanguage.setLanguage( ar[(ar.indexOf( myLanguage.language ) + 1) % 2] );
		e.stopPropagation();
		e.preventDefault();
		return false;
	}
} );

// Start with default language
myLanguage.setLanguage( localStorage['language'] || startLanguage || window.coreParams['language'] || 'en', true );

function langClick( e ) {
	let t = e.target;
	if( t && t.dataset['language'] ) {
		myLanguage.setLanguage( t.dataset['language'], false );
	}
}

myLanguage.fillSelect = ( panel, style ) => {
	if( elephCore.params['social'] ) return;
	if( myLanguages.length<=1 ) return;
	let lnames = {
		ru: 'Русский', uk: 'Українська', lt: 'Lietuvių',
		en: 'English', pl: 'Polskie'
	};
	if( style!=='sec' ) {
		for( let s of myLanguages ) {
			let el = construct( '.graybutton.language_selector ' +
				(lnames[s] || s), panel, langClick );
			el.dataset['language'] = s;
		}
	}
	// let details = style==='collapse'? construct( 'details', panel ) : null;
	if( !NEOBRIDGE ) {
		// Вторичный выбор языков в общем-то не нужен
		if( style==='main' ) return;
		for( let s of allLanguages ) {
			if( myLanguages.has( s ) ) continue;
			let el = construct( '.graybutton.language_selector ' +
				(lnames[s] || s), panel, langClick );
			el.dataset['language'] = s;
		}
	}
}

myLanguage.doSelect = e => {
	let el = e && e.target;
	if( !el ) return elephCore.showSettings();
	if( !myLanguage.selectBox ) {
		myLanguage.selectBox = makeBigWindow( { selectandhide: true } );
		let holder = construct( '.column', myLanguage.selectBox ),
			holdermain = construct( '.langselectbox.flexline', holder ),
			holdersec = construct( '.langselectbox.flexline', holder );
		myLanguage.fillSelect( holdermain, 'main' );
		myLanguage.fillSelect( holdersec, 'sec' );
	}
	myLanguage.selectBox.show();
}

myLanguage.getCountry = lang => {
	const ccc = {
		en: 'gb', uk: 'ua', cs: 'cz', da: 'dk', sq: 'al', el: 'gr'
	};
	return (ccc[lang] || lang).toUpperCase();
}

function needLoad( phrase ) {
	if( +phrase ) {
		if( LOCALTEST ) debugger;
		return;
	}
	if( notfound.has( phrase ) ) return;		// Она уже была не найдена
	toloadset ||= new Set;
	if( phrase.includes?.( '#' ) ) phrase = phrase.replace( '#', '' );
	toloadset.add( phrase );
	needLoadUnknown();
}
