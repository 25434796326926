import "./core.js";
import Table from "./mods/table.js";

import( './mods/hint.js' );

const MAGICIDLENGTH = 32;
const defaultNumericPrefix = FANTGAMES || NEOBRIDGE? 'team_' : 'room_';
function prefixMe( id ) {
	if( +id===9 ) return 'room_9';
	if( +id===20 ) return 'room_20';
	if( +id ) return defaultNumericPrefix + id;
	let m = id.match( /^(T|C)(\d+)$/ );
	if( m ) {
		return (m[1]==='T'? 'event_' : 'team_' ) + m[2];
	}
	return id;
}

let allrooms = new Map,
	viewRoom = null,
	openRoomNow = null,
	tables = new Map,
	checkTables = new Set,
	userTableSet = new Set,
	manyTables,
	subscribed,
	uniqmarker = 1,
	showed = false,
	clubs = new Map,
	isMyClub,
	// roomitem = '',
	// viewInfo = {},
	// showTourPanel = 0,
	hall,
	mediaWideRoom = window.matchMedia( '(min-aspect-ratio: 7/6)' );

	if( mediaWideRoom.addEventListener )
		mediaWideRoom.addEventListener( 'change', checkChat );
	else
		mediaWideRoom.addListener( checkChat );

class Room {
	constructor( id ) {
		this.id = id;
		this.itemid = id;
		this.setSubId( id.replace( '.', '_' ) ); //.replace( 'event_', 'room_' ) );

		this.chatItem = 'chat_' + id; // ( this.type==='room' && 'chat_room_' || 'chat_' ) + id;
		this.full = { empty: true, back: '' };
		this.door = createblock( '.roomdoor.grayhover.display_none', ['icon', 'title', 'counter'] );
		this.door.visible = false;
		this.door.o_title.classList.add( 'roundborder' );
		this.door.o_panel.onclick = doorclick;
		this.door.o_panel.y_room = this;
		this.door.o_counter.style.display = 'none';
		this.door.o_icon.appendChild( this.door.o_counter );
		this.door.o_anonymous = construct( '.anonymous.display_none', this.door.o_panel );

		// Спрячем зал покера от проверок Google Play, при виде покера приложение
		// оказывается несоответствующим уровню доступа, указанному у нас
		// if( window.cordova && !FANTGAMES && this.id==='room_36' && window.inapppurchaseOnly() )
		// 	this.door.o_panel.style.display = 'none';
		let m = id.match( /^(|team_|event_|user_|T|C)(\d+)$/ );
		if( m ) {
			if( m[1]==='event_' || m[1]==='T' ) this.tourid = m[2];
			if( m[1]==='team_' || m[1]==='C' ) this.tid = m[2];
		}
		this.door.o_panel.dataset.roomid = this.getLocation;

		allrooms.set( id, this );
		allrooms.set( this.getLocation, this );
	}

	get getLocation() {
		let alias = elephCore.backAlias.get( this.id );
		if( alias ) return alias;
		if( this.tourid ) return 'T' + this.tourid;
		if( this.tid ) return 'C' + this.tid;
		return this.id.replace( 'room_', '' ).replace( '_', '.' );
	}

	setSubId( value, resubscribe ) {
		if( this.subId===value ) return;
		this.unsubscribe();
		this.subId = value;
		this.origin = this.subId.replace( '_', '.' );
		this.subId = prefixMe( value );

		if( this.subId.startsWith( 'team_' ) ) {
			this.type = 'team';
			this.team = User.set( this.subId );
		} else
			this.team = null;
		if( this.subId.startsWith( 'room_' ) ) this.type = 'room';
		else if( this.subId.startsWith( 'event_' ) ) this.type = 'event';
		this.postfix = this.subId.split( '_' )[1];

		if( resubscribe ) {
			this.subscribe( false );
			updateObjects();
		}
	}

	setDoorVisible( vis ) {
		if( this.door.visible===vis ) return;
		log( 'Make door ' + this.getLocation + ' visible: ' + vis );
		this.door.visible = vis;
		this.door.o_panel.makeVisible( vis );
		vis || viewRoom===this? this.subscribe() : this.unsubscribe();
	}

	setcountplayers( cnt ) {
		if( this.door ) {
			let counter = this.door.o_counter;
			counter.textContent = cnt;
			counter.dataset['q'] = cnt;
			counter.style.display = cnt ? null : 'none';
		}
	}

	notfound( reason ) {
		// Не найдена подписка
		this.notfoundReason = reason;
		let mine = this===viewRoom;
		log( (mine? 'THIS ':'') + 'Room not found ' + reason );
		let triedroom = this.subId.startsWith( 'room_' );
		if( mine ) {
			// if( checkNumericAbsentId( this.postfix ) ) return;
			// Если это число, попробуем еще команду
/*
			if( triedroom && +this.postfix ) {
				log( 'Switch to team ' + this.postfix );
				this.setSubId( 'team_' + this.postfix, mine );
				return;
			}
*/
			if( this.tourid ) {
				// TODO: запрос турнира по API (возможно, его делает tourline)

			} else {
				// если турнир не обнаружен, тогда закрывать эту вкладку
				log( 'Switch to home' );
				fire( 'golocation', '' );
			}
		}
	}

	get isRoot() {
		let root = window.domainData?.root,
			f = this.full;
		if( !root || !f || f.empty ) return false;
		if( f.location===root ) return true;
		if( f.id.replace( 'team_', 'C' )===root ) return true;
		if( ( f.lback?.split(':')[0] || f.back )===root ) return true;
		return false;
	}

	parse( data, minor ) {
		let mine = this===viewRoom;
		if( minor==='DONE' || data==='NOTFOUND' ) {
			return this.notfound( 'DONE' );
		}
		if( this[minor]===data ) return;
		this[minor] = data;
		switch( minor ) {
			case 'full':
				// if( LOCALTEST && prev && prev.back ) return;
				log( 'Received .full room info' );
				let f = this.full,
					root = window.domainData?.root;
				f.itemid = f.id;		// For fillPlayerHTML
				// Проверим, можем ли мы вообще обрабатывать (или видеть) этот зал
				if( root ) {
					if( !this.isRoot ) {
						if( mine ) {
							delay( () => fire( 'golocation', '' ) );
							return;
						}
					}
				}
				if( f.redirectto && this.subId!==f.redirectto ) {
					if( mine ) log( 'Redirecting to ' + f.redirectto );
					this.setSubId( f.redirectto, mine );
				}
				if( !f.hall && !f.baseroom && !f.tourid && !f.tid ) return this.notfound( 'obsolete' );
				if( mine && elephCore.params['onehall'] && f.hall!==elephCore.params['onehall'] ) {
					delay( () => fire( 'golocation', '' ) );
				}
				this.checkdoor();
				this.baseroom ||= Room.set( f.baseroom ) || this;
				// let back = f.back;
				// if( elephCore.params['onehall'] &&
				// 	elephCore.params.tableviewstart===`${room.id}` ) back = null;

				if( 'gamephrase' in f ) {
					// Указана игра, покажем на иконке
					this.door.o_game ||= construct(`.game ${f.gamephrase}`, this.door.o_icon );
				}
				// this.back = Room.set( back );
/*
				if( this.team ) {
					this.team.members = +f.nmembers || f.members;
				}

*/
				if( this.team ) {
					if( f.currsymbol ) this.team.setFieldValue( 'currsymbol', f.currsymbol, 'currsymbol changed' );
					if( f.title ) this.team.setShowName( f.title );
				}

				if( mine ) {
					if( data.tourid ) {
						setTourLineId( data.tourid );
						petitionUrls.set( 'roomtour', `/tour/${data['tourid']}` );
					}
					updateObjects();
				}
				if( f.addname ) f.titleFull = f.title + ' ' + f.addname;
				if( f.location ) elephCore.setAlias( f.location, this.subId );
				break;
			case 'plrs':
				this.setcountplayers( data );
				break;
			case 'members':
				viewRoom.team?.setFieldValue( 'members', data );
				break;
			case 'easyreg':
				if( mine ) self.tourLine?.setEasyReg( data );
				break;
			case 'regers':
				// Покажем кнопки игры прямо в зале? Наверное всё же пока нет,
				// это может сбить новичков
				break;
		}
	}

	fullParse( o, minor ) {
		if( o==='NOTFOUND' ) {
			if( viewRoom===this && this.type==='room' ) {
				// No this room, drop to base
				// Сначала попробуем проверить есть ли такой турнир. Если есть, то просто покажем информацию о нем
				if( checkNumericAbsentId( this.postfix ) ) return;
				fire( 'golocation', '' );
			}
			return;
		}
		if( viewRoom!==this ) return;
		switch( minor ) {
			case 'tables':
				this.parseTables( o );
				break;
		}
	}

	parseTables( str ) {
		if( typeof str==='object' ) {
			// Примем Map
			for( let [k,_] of str ) {
				// let t = tables.get( k );
				mytable( k ).subscribe();
			}
			for( let [k,t] of tables ) {
				if( !str.has( k ) )
					hideTable( t );
			}
			return;
			// Map пока не принимаем, разобраться откуда сохраняется подписка
			// либо Map всегда, либо никогда
		}
		let ar = str.split( ' ' );
		let fullmark; //, strshow;
		if( str==='' )
			fullmark = uniqmarker++;		// Пустой MSET означает очистку
		for( let t of ar ) {
			if( !t ) continue;
			if( t==='-' ) {
				fullmark = uniqmarker++;
				// hidemytables();
				continue;
			}
			let parts = t.split( '=' ),
				idx = parts[0],
				hide = idx[0]==='-';
			if( idx[0]==='+' || idx[0]==='-' ) idx = idx.slice( 1 );
			// if( hide ) idx = idx.slice( 1 );
			let tableid = (this.postfix&&(this.postfix + '_')||'') + idx,
				table = tables.get( tableid );
			if( hide ) {
				hideTable( table );
			} else {
				if( !table || table.obsolete ) {
					// if( !strshow ) strshow = 'SUBSCRIBE table_' + prefix + '{';
					// strshow += idx + ' ';
					table = mytable( tableid );
				}
				if( fullmark ) table.umark = fullmark;
			}
		}
		// if( strshow )
		// 	elephCore.toserver( strshow + '}\n' );
		if( fullmark ) {
			tables.forEach( t => {
				if( t.umark!==fullmark ) hideTable( t );
			} );
		}

		// Те мои столы, что есть в зале, не показываем наверху
		this.checkMyTables();
	}

	checkMyTables() {
		for( let o of $$( '#mygamespanel > .table' ) )
			o.makeVisible( o.dataset.room!==this.id.split( '_' )[1] );
	}

	doorsParse( o, minor ) {
		// this[minor] = o;
		if( minor==='doors' ) {
			this.doors = o.map( x => ( +x && 'room_' + x ) || x );
			if( this===hall ) checkDoors();
		}
	}

	subscribe( full ) {
		this.notfoundReason = null;
		if( !this.sub ) this.sub = elephSubscribe.add( this.subId, this.parse.bind( this ) );
		if( full && !this.subFull /*&& ( this.type==='team' || this.type==='room' )*/ ) {
			let roomno = this.full?.room || this.postfix;
			this.subFull = elephSubscribe.add( 'roomfull_' + roomno, this.fullParse.bind( this ) );
		}
	}

	subscribedoors() {
		if( !this.subd && this.type==='room' || this.type==='team' )
			this.subd = elephSubscribe.add( 'roomdoors_' + this.postfix, this.doorsParse.bind( this ) );
	}

	unsubscribe( full ) {
		this.sub?.release();
		this.sub = null;
		if( full ) {
			if( this.subFull )
				this.subFull.release();
			this.subFull = null;
		}
	}

	unsubscribedoors() {
		if( this.subd ) this.subd.release();
		this.subd = null;
	}

	getfull( id ) {
		return this['full'] && this['full'][id];
	}

	checkdoor() {
		if( !this.full ) return;
		let d = this.door, f = this.full;
		let s = '';
		if( checkMS( f.plantime ) ) {
			let d = new Date( checkMS( f.plantime ) );
			if( (d.getTime()-Date.now())/1000 > 60*60*24 )
				s = d.toLocaleString().slice( 0, 5 ) + '. ';
			else
				s = d.toLocaleTimeString().slice( 0, 5 ) + '. ';
		}
		d.o_title.setContent( s + f.title );
		d.o_icon.style.backgroundImage = `url(${getHiresIcon( f.icon, 128 )})`;
		d.o_anonymous.makeVisible( f.anonymous || false );
		this.checkDoorVisibility();
		return d;
	}

	checkDoorVisibility() {
		// Считаем, что наша дверь из текущего холла (почему?). Тогда двери в турниры не видны из других турниров
		let vis = false,
			doors = viewRoom.baseroom?.doors;
		if( doors?.includes( this.getLocation ) || doors?.includes( this.subId ) ) {
			if( viewRoom.team || viewRoom.baseroom===viewRoom ) vis = true;
			else if( !viewRoom.getfull( 'dynamic' ) && this.getfull( 'dynamic' ) ) vis = true;
		}
		if( FANTGAMES && this.subId==='room_41' && ( !UIN || +UIN>630000 ) ) vis = false;
		this.setDoorVisible( vis );
	}

	static get( id ) {
		if( !id ) return null;
		return allrooms.get( id.toString() );
	}

	static set( id ) {
		if( !id ) return;
		id = id.toString().replace( '.', '_' );
		id = prefixMe( id );
		let r = Room.get( id ) || new Room( id );
		return r;
	}
}

const
	roomArea = construct( '.room.animatedroom' ),
	self = {
		holder: roomArea,
		previewHTML: construct( 'iframe.display_none', roomArea )
	};
	self.headersArea = html( `<div class='headers_area column' style='grid-area: headers'></div>`, roomArea );
	self.hintArea = html( `<div id='hints' class='hints column' style='order: -50'></div>`, self.headersArea );
	self.doorsTableArea = html( `<div class='display_none doorstable_area' style='pointer-events:none'></div>`, roomArea ); // Не используем column, потому что объект превышает границы видимой области и должен скроллироваться
	self.myselfArea = construct( '.myself_area', self.headersArea );
	if( GAMBLERRU )
		self.clubsArea = html( "<div class='display_none clubs_area' style='text-align: center; max-width: 100%; overflow: scroll; white-space: nowrap; pointer-events: initial; font-size: min(3vw,1rem)'></div>", self.doorsTableArea );
	else
		self.clubsArea = html( `<div class='display_none clubs_area flexline' style='pointer-events: initial'></div>`, self.doorsTableArea );
	self.doorsArea = html( `<div class='display_none visible doors_area' 
		style='pointer-events: initial; grid-area: doors;
    	display: flex; flex-flow: row wrap; align-items: center; justify-content: space-evenly;
    	text-align: center;
    	font-size: var( --bigfont ); // 1rem; // Минимальный фонт названий залов на тлф
	    overflow: hidden;'>
		</div>`, self.doorsTableArea );
	self.tablesArea = construct( '.room_tables.fade.visible.hideempty', self.doorsTableArea );
	self.promoArea = html( `<div class='display_none promoarea flexline spacearound' style='pointer-events: initial;'></div>`, self.doorsTableArea );
	// self.tourLine = new Tourline( self );

window.elephRoom = self;

roomArea.addEventListener( 'scroll', () => {
	// log( 'Scroll room: ' + JSON.stringify( e ) );
} );

function doorclick( e ) {
	let t = e.currentTarget,
		r = t.dataset.roomid;
	if( r ) {
		setViewRoom( Room.get( r ) );
		e.stopPropagation();
	}
}

let chatRoom, narrowChat, narrowPanel;
// chat.setReadOnly( true );

dispatch( 'goroom', goroom );

async function goroom( path ) {
	if( viewRoom && !path ) {
		// Просто активировать зал
		window.Swiper?.act( self.holder.closest( '.swipe_page' ) );
		updateObjects();
		restoreSubscriptions();
		return;
	}
	let item = await elephCore.checkLocation( path );
	log( 'Catched goroom ' + path + ', resolved as ' + item );
	if( !item.match( /^(room_|team_|event_|user_|T|C)(\d+)$/ ) ) item = '9';
	setViewRoom( item );
	// prepareSubscription();
}

dispatch( 'roomback', () => {
	if( !viewRoom ) return;
	let f = viewRoom.full;
	fire( 'golocation', f.lback || f.back || f.hall || f.team?.location );
} );

function compare( t1, t2 ) {
	if( t1.z!==t2.z ) return t1.z - t2.z;
	if( t1.mine!==t2.mine ) return t1.mine ? -1 : 1;
	const x = t2.color - t1.color;
	if( x ) return x;
	if( t1.bet!==t2.bet ) return t2.bet - t1.bet;
	return t1.number - t2.number;
}

async function inviteTeam() {
	makeBigWindow( `
		<div class='vertical_form'>
		<span>{Inviteinateam}</span>
			<input class='email' tabindex='1' name='email' type='text' autocomplete="inviteemail" maxlength='50' placeholder='Email' />
			<input type='hidden' name='team' value='${viewRoom.full.team}'></input>
			<button data-apirequest='invite'>{Invite}</button>
        </div>` ).show();
}

async function uploadPicture( e ) {
	if( e.target.tagName==='BUTTON' )
		(await import( './mods/upload.js' ))
			.doupload( viewRoom.origin );
}

function inviteMembers() {
	let team = viewRoom.team;
	// Переменная задается заранее для того, чтобы при задержке в загрузке модуля не поменялась команда
	import( './mods/teamadmin.js' ).then( mod => {
		mod.teamInvite( team );
	} );
}

function showPlayers() {
	// Покажем игроков клуба, отсортированных по последним сообщениям
	if( !self.team ) return;
	import( './mods/teamadmin.js' ).then( mod => mod.teamMembers( self.team, {
		showChatCounter: true
	} ) );
}

async function createTournament() {
	if( !localStorage.infoshowed_tournamentcreate ) {
		let infowin = makeBigWindow( {
			id: 'info_tournamentcreate',
			picture: viewRoom.team?.getPicture,
			title: '️{New} {tournament}',
			html: `<span class='biginfo'>{Biginfo_tournamentcreate}</span>
				<button class='default' data-closeselect='create' style='padding: 0.5em 1em'>{Create} {tournament}</button>`
		});
		let infores = await infowin.promiseShow();
		if( infores!=='create' ) return;
		localStorage.infoshowed_tournamentcreate = true;
	}

	let game = viewRoom.full.game || coreParams.defaultgame;
	if( game ) {
		// Авторизацию запрашиваем всегда, кроме демо-клуба
		(await import( `./mods/${toureditModule()}.js` )).create( {
			game: game,
			team_id: viewRoom.full.tid,
			noauth: viewRoom.team?.isDemoclub
		} );
	} else {
		// ?? Возможно, одного вызова selectGame хватит, если он обрабатывает одну игру
		(await import( './mods/reger.js' )).selectGame(
			viewRoom.team?.games || viewRoom.full.games || viewRoom.full.game || viewRoom.full.hall, {
				action: 'newtour',
				team: viewRoom.team
			} );
	}
}

async function createMatch( e ) {
	await elephCore.checkAuth( 'complete' );
	if( LOCALTEST || window.NEOBRIDGE || viewRoom.full.game==='BRIDGE' ) {
		let str = `type=friendgame data="${e.currentTarget.dataset.createdata}"`;
		elephCore.sendPlay( str );
	}
}

async function createRubberGame() {
	await elephCore.checkAuth( 'complete' );
	let str = `type=friendgame data="game=bridge mp=4 rubber=yes tid=${viewRoom.full.team || 0}"`;
	elephCore.sendPlay( str );

}

async function makeNewGame( e ) {
	log( 'Make new game for ' + JSON.stringify(viewRoom.full) );
	if( !viewRoom.full.autotables && !viewRoom.full.mayplay ) return;
	if( viewRoom.team && ( LOCALTEST || viewRoom.team.isCaptain && +viewRoom.team.members<=1 ) ) {
		let check = await makeBigWindow( {
			repeatid: 'newgamenoplayers',
			picture: viewRoom.full.picture,
			title: `${viewRoom.team.getShowName}. 🤷 {Noplayers}`,
			html: `<PRE style='margin: 0.5em 1em'>{Newgamenoplayers,${viewRoom.team.getShowNameEnc}}</PRE>
					<button class='default importantsize' data-closeselect='invite' style='margin: 0.5em'>{Invite}</button>
					<span class='control ' data-closeselect='later' style='color: var( --light_gray ); margin: 0.5em'>{Notnow}</button>`
		}).promiseShow();
		if( check==='invite' ) {
			inviteMembers();
			return;
		}
		// if( check!=='later' ) return;
	}
	e?.target.setSpinner( true );
	let mod = await import( './mods/reger.js' );
	e?.target.setSpinner( false );
	mod.selectGame(
		viewRoom.team?.games || viewRoom.full.games || viewRoom.full.game || viewRoom.full.hall, {
			team: viewRoom.team,
			room_id: viewRoom.subId
		});
}

async function startAnalysis() {
	// Input URL location or any data for analysis
	let solo = await import( './mods/solo.js' );
	solo.soloGo( {
		id: 'analysis',
		game: 'bridge'
	});
}

async function playFriends() {
	// let modauth = await import( './auth.js' );
	// await modauth.default.checkAuthGuest();
	await window.modules.Auth.checkAuthGuest();
	elephCore.sendPlay( `type=friendgame data="game=bridge time=10m"`, true );
}

dispatch( 'loggedin', checkTD );

dispatch( 'loggedout', () => {
	self.clubsArea.innerHTML = '';
	clubs.clear();
});

function checkTD() {
	if( !viewRoom ) return;
	let f = viewRoom.full;
	if( f.empty ) return;
	if( f.type==='team' && !f.tid ) f.tid = viewRoom.postfix;

	let	admin = elephCore.ADMIN || (f.td?.includes( +UIN )),
		game = NEOBRIDGE && 'bridge' || f.game || LOCALTEST && 'bridge',
		team = viewRoom.team,
		myteam = elephCore.getMyTeam( f.tid ),
		iscap = myteam==='C';

	self.promoArea.makeVisible( coreParams.promo && !f.back && !f.tourid );

	self.promoArea.$( '.createclub' )?.makeVisible( !self.clubsArea.children.length );

	/* Neobridge. Analysis button */
	if( NEOBRIDGE || LOCALTEST ) {
		self.analysisButton ||= html( `<div style="order:-10;" class='dooricon control grayhover column'><span class='emoji big'>️🔍</span><span>{Analysis}</span>`,
			startAnalysis, self.promoArea );

	}
/*
	Кнопки игры с друзьями и создания матча
	if( coreParams.promo?.play	friendsbridge || LOCALTEST ) {
		self.playFriendsButton ||= html( `<div style="order:-10;" class='dooricon control grayhover column'><span class='emoji big'>️🐻</span><span>{Play} {withfriends}
				</span><span style='font-size: 1rem'>{Noregistration}</span>`, playFriends, self.promoArea );
		self.playFriendsMatch ||= html( `<div style="order:-10;" class='dooricon control grayhover column'><span class='emoji big'>️⚔️</span><span>{Match}
				</span><span style='font-size: 1rem'>4x4</span>`, playMatch, self.promoArea );
	}
*/

/*
	if( NEOBRIDGE || FANTGAMES || LOCALTEST ) {
		self.promoOrgLabel ||= html( `<span style='width: 100%; order: -5; text-align: center; font-style: italic; color: gray' >Enthusiast</span>`, self.promoArea );
	}
*/

	if( coreParams.promo?.createclub || LOCALTEST ) {
/*
		self.createClub ||= html( `<div style="order:-2;" class='dooricon control grayhover column'><span class='emoji big'>️🎩</span><span>{Myclub}
				</span><span class='display_none' style='font-size: 1rem'>For regular/private events</span>`, () => {
			import( './tools.js' ).then( mod => mod.createClub() );
		}, self.promoArea );
*/
		self.createClub ||= html( `<div style="order:-2;" class='display_none visible createclub roomdoor grayhover'>
				<span class='icon' style='background-image: url( ${IMGPATH}/icons/svg/tophatblue )'>️</span>
				<span class='title roundborder'>{Createclub}</span>
				<span class='display_none' style='font-size: 1rem'>For regular/private events</span>`, () => {
			import( './mods/team.js' ).then( mod => mod.createClub() );
		}, self.promoArea );
		self.createClub.makeVisible( !self.clubsArea?.children.length );
	}

/*
	if( NEOBRIDGE || LOCALTEST ) {
		// self.createTournament ||= html( `<button data-execute='touredit.create' style="order:-2" class='display_none control flexline'><span class='emoji'>️🛡️️</span><span>{Createtourney}</span>`, self.doorsArea );
		self.createTournamentButton ||= html( `<div style="order:-3;" class='dooricon control grayhover column'><span class='emoji big'>️🛡️</span><span>{Createtourney}
				</span><span style='font-size: 1rem'>Completely automatic</span>`, createTournament, self.promoArea );
	}
*/

	if( +viewRoom.id>90000 ) {
		for( let n of [ 'inviteButton', 'createTourButton', 'createTourButton', 'createRubber', 'matchButton' ] )
			self[n]?.hide();
		return;
	}		// На всякий случай если большой номер, предварительно считаем, что это турнир

	// if( !window.LANGUAGELOADED ) return;

	// Кнопка основного действия. Доступна только тогда, когда нет игровых столов, Находится в центре doors_area
	self.defaultCenterButton ||= html( `<button class='display_none default big hideempty' style='margin-top: 20vh'>️</button>`, self.doorsArea );
	if( +team?.members===1 && team.isHelper ) {
		self.defaultCenterButton.dataset.execute = `teamadmin.teamInvite`;
		self.defaultCenterButton.dataset.team_id = team.id;
		self.defaultCenterButton.setContent( '{Invitein} ' + team.getShowName );
		self.defaultCenterButton.show();
	} else {
		self.defaultCenterButton.textContent = '';
		self.defaultCenterButton.hide();
	}

	// Капитан команды может пригласить игрока
	if( LOCALTEST || f.team && window.ADMIN ) {
		if( !self.inviteButton ) {
			self.inviteButton = html( `<div style="order:-2" class='display_none dooricon control column'><span class='emoji big'>️➕</span><span>{Invite}</span>`, inviteTeam, self.doorsArea );
		}
		self.inviteButton.show();
	} else self.inviteButton?.hide();

	// Если командный зал, можем создать турнир
	if( LOCALTEST ) {
		self._debug_createTourButton ||= html( `<button data-execute='touredit.create' data-games='poker pref' style="order:-2" class='control flexline'><span class='emoji'>️TEST️️</span><span>{Createtourney} pkr/prf</button>`, self.doorsArea );
		self._debug_createVugraphButton ||= html( `<button data-execute='toureditnew.createvugraphmatch' >Vugraph match</button>`, self.doorsArea );
	}

	if( LOCALTEST || iscap && game ) {
		self.createTourButton ||= html( `<button data-execute='touredit.create' style="order:-2" class='display_none control flexline'><span class='emoji'>️🛡️️</span><span>{Createtourney}</span>`, self.doorsArea );
		// self.createTourButton ||= html( `<button style="order:-2" class='display_none control flexline'><span class='emoji'>️🛡️️</span><span>{Createtourney}</span>`, createTournament, self.doorsArea );
		self.createTourButton.show();
	} else self.createTourButton?.hide();

	if( ( isMyClub || ( !f.tid && !window.GAMBLERRU ) ) && ( f.autotables || f.mayplay ) ) {
		// Не нужно показывать "новая игра", если менеджер, у которого в команде пока никого нет
		self.gamePanel ||= html( `<div class='flexline gamepanel sheet importantsize display_none visible portrait_spaceevenly' style='order: -5; padding: 0; border: none'>
			<div class='favorites display_none hideinportrait' style='font-size: 1rem'></div></div>`, self.headersArea );
/*
		self.newGame ||= html( `<span class='display_none'><button data-action='newgame' class='column center' style='justify-content: center;'>
			<span>{Game}</span>
				<span class='hideinportrait noplayers display_none' style='font-size: 1rem; color: gray'>{Clubnoplayersyet}</span>
			</button>
			</span>`, self.gamePanel, makeNewGame );
*/
		self.newGame ||= html( `<button data-action='newgame' class='display_none column center' style='justify-content: center;'>
			<span>{Game}</span>
				<span class='hideinportrait noplayers display_none' style='font-size: 1rem; color: gray'>{Clubnoplayersyet}</span>
			</button>`, self.gamePanel, makeNewGame );
		self.favorites ||= self.gamePanel.$( '.favorites' );
		if( team?.isHelper || team?.canAdmin ) {
			// Create tournament button
			self.newTournament ||= html( `<span class='display_none'>
			<button data-action='createtournament' data-spinner='api' class='column center' style='justify-content: center; margin: 1rem 0'>{Tourney}</button>
			</span>`, self.gamePanel, createTournament );
			self.newTournament?.show();
		} else self.newTournament?.hide();
		let noplayers = self.newGame.$( '.noplayers' );
		if( +team?.members===1 ) {
			noplayers.setContent( `{Clubnoplayersyet,${team.getShowNameEnc}}` );
			noplayers.show();
		} else
			noplayers.hide();
		if( team?.isHelper && team?.members>1 && !team.isDemoclub || LOCALTEST ) {
			self.playersChat ||= html( `<span class='control icon display_none invertdark' 
				style='order: -1; width: 64px; height: 64px; margin: 0 0.5em;
				background-image: url( ${IMGPATH}/svg/icons/ic_people_outline_black_24px.svg );'></span>`,
				self.gamePanel, showPlayers );
			self.playersChat?.show();
		} else self.playersChat?.hide();
		self.newGame.show();
		checkManyTables();

/*
		// Fill favorite game
		let fav_json = !team?.isDemoclub && localStorage[`last_newgame_${team?.numericid}`];
	Favorites switched off 23/08/23, due to misunderstanding of testers
	(seems like an a playing table)
		if( self.lastFavorites!==fav_json ) {
			if( fav_json ) {
				let fav = JSON.parse( fav_json );
				self.favorites.html( `<button class='flexline' data-action='favorite' data-serveraction='${fixedEncodeURIComponent(fav.serveraction)}'>${fillPlayerHTML( fav )}</button>` );
				self.favorites.show();
			} else
				self.favorites.hide();
		}
		self.lastFavorites = fav_json;
 */
	} else {
		// self.favorites?.hide();

		self.newGame?.hide();
		self.newTournament?.hide();
		self.lastFavorites = null;
	}

	if( f.tid && f.closed && !isMyClub ) {
		// Закрытый клуб
		self.closedInfo ||= html( `<div class='display_none column center'>
					<div class='sheet'>{Biginfo_closedclub}</div>
					<button class='display_none importantsize default' style='margin-top: 1em' data-execute='auth.signin'>{Signin}</button>
					</div>`, self.headersArea );
		self.closedInfo.$( 'button' ).makeVisible( window.isGuest() );
		self.closedInfo.show();
	} else self.closedInfo?.hide();

	if( LOCALTEST ) {
		// self.tgButton ||= html( `<button style="order:-2" class='display_none control flexline' data-execute='tg.join'><span class='emoji'>↗️️️</span><span>Telegram</span>`, self.doorsArea );
		self.tgButton ||= html( `<button style="order:-2" class='display_none control flexline' data-games='bg-long' data-execute='touredit.create'><span class='emoji'>↗️️️</span><span>DominoTour 3 {month_num:3}</span>`, self.doorsArea );
		self.tgButton.show();

		self.openTour ||= html( `<button data-execute='touredit.create'  
		data-open='1' style="order:-2" class='column display_none'><span><span class='emoji'>️🛡️️</span><span>{Opentournament}</span></span>
				<span class='subtitle'>Opened for everyone</span>`, self.doorsArea );
		self.openTour.show();

		self.openUsers ||= html( `<button data-execute='usersview.show'  
		style="order:-2" class='column display_none'><span><span class='emoji'>💬️️</span><span>{Messages}</span></span>
				<span class='subtitle'>Messages</span>`, self.doorsArea );
		self.openUsers.show();

		self.testEdit ||= html( `<button data-edit='TEST'
		style="order:0" class='column display_none'>Test edit</button>`, self.doorsArea );
		// self.testEdit ||= html( `<button data-confirm='TEST' data-api='test'
		// style="order:0" class='column display_none'>Test edit</button>`, self.doorsArea );
		self.testEdit.show();

		if( !$( '#testtimemark' ) ) {
			html( '<div id="testtimemark">Test time edit 1<TIME style="background: green" data-editable=1 data-datatype="datetime-local">10:00</div>', self.doorsArea );
			html( '<div>Test time edit 2<TIME style="background: green" data-editable=1 data-datatype="datetime-local">EE</div>', self.doorsArea );
		}
	}

	// Если в моей команде нет лого, предложим добавить
	window.hint?.update?.( team );

	// Лэндинг показываем не авторизованным, или если нет ни одного клуба
	let canland = !UIN || self.clubsArea.children.length===0;
	if( !viewRoom.full.back && window.landHTML && canland ) {
		if( !self.landElement ) {
			self.landElement = html( window.landHTML, roomArea, e => e.target.$( 'video' )?.play() );
			self.landElement.onscroll = e => {
				fire( 'landingscroll', e );
			}
			// self.landElement = html( window.landHTML, roomArea, e => e.target.$( 'video' )?.play() );
		}
		self.landElement.show();
		self.doorsTableArea.hide();
		self.promoArea.hide();
		checkChat();
		fire( 'landingscroll' );
	} else {
		self.landElement?.hide();
		fire( 'landingscroll' );
		self.doorsTableArea.makeVisible( !self.tourLine?.isFinished );
		self.promoArea.makeVisible( coreParams.promo && !f.back && !f.tourid );
	}


	// Кнопка "Матч на 4", "Матч на 8" и "Роббер"
/*
	if( myteam && game==='bridge' ) {
		if( !self.createBridge ) {
			self.createBridge = {
				match4: html( `<div style="order:-2" class='display_none dooricon control column' data-createdata="play=bridgematch4"><span class='emoji big'>🗡️️</span><span>{Match}-4</span>`, createMatch, self.doorsArea ),
				match8: html( `<div style="order:-2" class='display_none dooricon control column' data-createdata="game=asker collect=match"><span class='emoji big'>⚔️️️</span><span>{Match}-8</span>`, createMatch, self.doorsArea ),
				rubber: html( `<div style='order:-2' class='display_none dooricon control column'><span class='emoji big'>🦆️</span><span>{Rubber}</span>`, createRubberGame, self.doorsArea )
			}
		}
		for( let o of Object.values( self.createBridge ) ) o.show();
	} else {
		if( self.createBridge )
			for( let o of Object.values( self.createBridge ) ) o.hide();
	}
*/
}

function checkManyTables() {
	let c = self.tablesArea.childElementCount,
		mt = c>=4 || viewRoom.full['playroom'];
	// Если нет столов, и это турнир, покажем таблицу (перерыв-регистрация-завершен)
	self.newGame?.classList.toggle( 'default', !c );
	self.gamePanel?.classList.toggle( 'rem2', !c );
	if( manyTables===mt ) return;
	manyTables = mt;
	roomArea.setAttribute( 'manytables', manyTables ? 1 : 0 );
}

function insertTable( t ) {
	if( t.holder.parentElement ) return;
	t.holder.classList.remove( 'removed' );
	// Находим подходящее место для стола и вставляем его туда
	let tt = self.tablesArea.firstChild;
	for( ; tt; tt = tt.nextSibling ) {
		if( !tt.table || compare( t, tt.table )<=0 ) break
	}
	self.tablesArea.insertBefore( t.holder, tt );
	checkManyTables();
	delay( checkDoorsAreaPointerEvents );
}

function delTable( t/*, dontsend*/ ) {
	if( !t ) return;
	if( t.tableID ) {
		tables.delete( t.tableID );
	}
	t.release();
	delay( checkDoorsAreaPointerEvents );
}

function hidemytables() {
	let freed = 0;
	for( let [k, t] of tables ) {
		t.release();
		freed++;
	}
	if( !freed ) return;
	tables.clear();
	delay( checkDoorsAreaPointerEvents );
}

let noTablesLast;
function checkDoorsAreaPointerEvents() {
	// Made for IFRAME scrolling. But doesn't work
	// If no tables then disable pointer events for doorstablearea
	// or just make it invisible
	let notables = !tables.size;
	if( noTablesLast===notables ) return;
	noTablesLast = notables;
	self.tablesArea.style.pointerEvents = notables? 'none' : 'initial';
}

function sendLocation() {
	if( !showed || !viewRoom ) return;
	let loc = viewRoom.full.location ?? viewRoom.getLocation;
	if( viewRoom.isRoot ) loc = '';
	elephCore.setLocation( loc, viewRoom.full.title );
}

function getDoors() {
	return viewRoom?.baseroom?.doors || [];
}

function setViewStyles() {
	if( !viewRoom ) return;
	viewRoom.subscribe();			// Убедимся, что подписаны
	let full = viewRoom.full,
		team = viewRoom.team;
	if( team?.loading && team.loading!=='DONE' ) return;		// Обновимся, когда загрузится
	self.defaultCenterButton?.hide();
	// if( !full || !full.title ) return;
	// Возврат возможен, если мы открыты не в чужом фрейме
	// также необходимо проверить внешний домен
	let back = ( full.lback || full.back || full.hall || viewRoom.tourid ) && 'roomback';
	if( full.location && full.location===window.domainData?.root ) back = null;
	elephCore.setBack( back );
	fire( 'settitle', full  );
	// Установим параметры для личного блока справа вверху
	// Если мы в клубе, то там баланс или "Управление клубом"
	// На главной странице пока оставляем прежний вид
	setHall( viewRoom.baseroom );
	let bgid = full?.background || team?.picture || full?.icon;
	if( full.nobackground ) bgid = null;

	if( bgid ) {
		let magic = bgid.length===MAGICIDLENGTH,
			url = !magic && `${IMGPATH}/icons/hires/${bgid}` || `${STORAGE}/images/origin/${bgid}`,
			filterbg = magic? 'grayscale(1) opacity(0.2)' : 'grayscale(1) brightness(0.5) opacity(0.1)';
		self.pictureBg ||= html( '<div class="display_none" loading="lazy" style="position: absolute; top: 0; z-index: -1000; width: 100%; height: 100%;"></div>', self.doorsTableArea );
		self.pictureBg.style.background = `url( ${url} ) center top/contain no-repeat`;
		// self.pictureBg.src = url; // `url( ${url} ) center top/cover no-repeat`;
		self.pictureBg.style.filter = filterbg;
		self.pictureBg.show();
	} else
		self.pictureBg?.hide();
	if( full?.bgurl ) {
		self.previewHTML.src = full.bgurl;
		self.previewHTML.show();
		self.tablesArea.hide();
		self.doorsArea.hide();
	} else {
		log( 'viewRooom tourid ' + viewRoom.tourid );
		if( !viewRoom.tourid ) self.previewHTML.hide();
		self.doorsArea.show();
	}
	self.gamePanel?.makeVisible( ( isMyClub || !viewRoom.team ) && ( full?.autotables || full?.mayplay ) || false );
	// checkVisibleDoors();
	checkDoors();
	sendLocation();
	self.tourLine?.checkTourReg();
	checkChat();
/*
	if( viewRoom.full.team ) {
		elephCore.checkAuth( 'complete' );
	}
*/
}

dispatch( 'friendsblinked', checkChat );

async function checkChat() {
	if( !viewRoom ) return;
	let chatVisible = self.tourLine?.id>0 || viewRoom.team || false,
		usersVisible = !window.SOLO && window.UIN, // self.manager?.friends.count,
		blockVisible = chatVisible || usersVisible;
	if( usersVisible && FANTGAMES && !viewRoom.full.back && !LOCALTEST ) usersVisible = false;
	if( FANTGAMES && !viewRoom.team && !self.tourLine?.id && !LOCALTEST ) blockVisible = false;
	if( !UIN ) blockVisible = false;
	if( blockVisible || chatRoom ) {
		narrowPanel ||= html( '<div class="narrowpanel display_none" style="max-height: 100%; overflow-y: auto"></div>' );
/*
		if( !chatUsers ) {
			let mod = await import( "./usersview.js" );
			chatUsers ||= new mod.Chatusers( invisibleBody() );
			chatUsers.holder.style.order = -1;
		}
*/
		if( viewRoom.team ) {
			let team = viewRoom.team;
			if( isMyClub ) {
				if( narrowChat?.id!==team.id ) {
					let mod = await import( "./mods/chat.js" );
					narrowChat = mod.Chat.set( {
						id: team.id,
						noauth: team.isDemoclub,
						size: 24,
						title: 'chat',
						parent: narrowPanel
					} );
				}
			}
		} else if( viewRoom.full.back ) {
			if( !chatRoom ) {
				// let mod = await promiseModule( 'chat' );
				// if( !mod ) return;
				let mod = await import( "./mods/chat.js" );
				chatRoom ||= new mod.Chat( {
					id: 'room',
					classname: 'room_chat',
					parent: narrowPanel
				} );
				// chat.setId( 'room' );
				chatRoom.defaultType = 'room';
			}
			narrowChat = chatRoom;

			// chatUsers.setChat( chatRoom );
			let chatid = viewRoom.full.chatid || viewRoom.subId,
				channel = viewRoom.full.chatchannel || viewRoom.chatItem || 'chat_' + chatid;
			chatRoom.defaultRecipient = chatid;
			chatRoom.subscribeItem( channel );
		} else {
			narrowChat = null;
		}

		if( narrowChat ) {
			if( narrowChat.parentElement!==narrowPanel ) narrowPanel.appendChild( narrowChat.holder );
			narrowPanel.setOnlyChildVisible( narrowChat.holder );
			narrowChat.show();
		}

		// if( self.manager?.friends )
		// 	chatUsers.setUsers( self.manager.friends );
		// chatUsers.chat?.checkTitle( blockVisible && mediaWideRoom.matches ? '{Chat}' : 'default' );
		// chatUsers.chat?.holder.makeVisible( chatVisible );
		// let parent = chatUsers.holder.parentElement,
		// 	newparent = mediaWideRoom.matches && blockVisible ? roomArea : invisibleBody();
		// if( parent!==newparent ) newparent.appendChild( chatUsers.holder );

		let parent = narrowPanel.parentElement,
			newparent = mediaWideRoom.matches && blockVisible ? roomArea : invisibleBody();
		if( parent!==newparent ) newparent.appendChild( narrowPanel );
	}
	let visible = blockVisible && mediaWideRoom.matches && narrowChat || false;
	roomArea.classList.toggle( 'withchat', visible );
	narrowPanel?.makeVisible( visible );
}

function setHall( room ) {
	if( hall===room ) return;
	if( hall ) {
		// Remove all doors
		hall.unsubscribedoors();
	}
	hall = room;
	if( hall ) {
		hall.subscribedoors();
		if( hall.doors ) checkDoors();
	}
}

function checkDoors() {
	let doors = hall?.doors?.map( x => x.toString() ) || [];
		if( typeof doors!=='object' ) return;

	let set = new Set;
	for( let r of doors ) {
		let room = Room.set( r );
		if( room ) {
			showDoor( room );
			set.add( room.door.o_panel );
		}
	}
	for( let i=self.doorsArea.children.length; i--; ) {
		let panel = self.doorsArea.children[i];
		if( panel.classList?.contains( 'roomdoor' ) && !set.has( panel ) )
			panel.remove();
	}

	self.defaultCenterButton?.makeVisible( doors.length===0 );
}

function storeView() {
	if( !viewRoom ) return;
	sessionStorage['tableview'] = viewRoom.id;
	localStorage['lasttableview'] = viewRoom.id;
}

dispatch( 'setroomnow', id => {
	openRoomNow = id;
} );

function setViewRoom( room ) {
	if( typeof room!=='object' ) room = Room.set( room );
	if( !room ) return;
	if( viewRoom===room ) {
		// Даже если пытаемся найти в тот же зал, возможно надо обновить подписка (если была NOTFOUND)
		if( !viewRoom.notfoundreason ) return;
		log( 'Resubscribe not found room' );
	}
	hidemytables();
	dropSubscriptions( true );
	log( 'SetViewRoom: ' + room.id );
	if( room.full?.hall ) fire( 'hallchanged', room.full.hall );
	self.viewRoom = viewRoom = room;
	let pb = $( '#playbutton' );
	if( pb ) pb.dataset.hall = viewRoom.hall || '';
	roomArea.dataset.roomid = room.id;
	roomArea.classList.toggle( 'landing', room.id==='room_9' );
	// || ( (+viewRoom.id<50000) && 'team_' + viewRoom.id ) || null;
	updateObjects();
	restoreSubscriptions();
	window.hint?.update();
}

async function setTourLineId( id ) {
	if( !id && !self.tourLine ) return;
	if( !self.tourLine ) {
		let mod = await import( './mods/tourline.js' );
		self.tourLine ||= new mod.Tourline( self );
	}
	self.tourLine.setId( id );
}

function updateObjects() {
	let eventId = viewRoom.tourid || viewRoom.full.tourid;
	// Tourline on request
	setTourLineId( eventId );
	self.team = viewRoom.team;
	window.defaultClub = viewRoom.team;
	isMyClub = self.team?.isMember || LOCALTEST;

	setViewStyles();
	storeView();
	checkPlayButton();
	checkManyTables();
	checkTD();
	self.teamLine?.update();
	// Для некоторых подписок сделаем отдельный запрос на сервер (разовый)
	// Например, это может быть вход в командный зал
	if( viewRoom.team && LOCALTEST ) {
		viewRoom.team.setShowName( viewRoom.full.title );
		viewRoom.team.setPicture( viewRoom.full.picture );
	}

	if( viewRoom.team && !self.teamLine ) {
		// log( 'Room team: ' + JSON.stringify( viewRoom.team ) );
		import( './mods/team.js' ).then( mod => {
			self.teamLine ||= new mod.TeamLine( self );
			// self.teamLine.update();
		});
	}
	self.clubsArea.makeVisible( !viewRoom.full.back && !self.team && !eventId );
	log( `Update room objects with viewRoom: ` + JSON.stringify( viewRoom.full ) );
}

function checkPlayButton() {
	let pb = $( '#playbutton' );
	if( !pb ) return;
	let f = viewRoom.full;
		// myteam = elephCore.getMyTeam( f.tid );
	if( FANTGAMES && !LOCALTEST ) return pb.hide();
	let pbvisible = !f.empty && f.type!=='tour' && !f.noplay && !viewRoom.team;
	pb.makeVisible( pbvisible );
	log( `#playbutton visible: ${pbvisible}` );
}

function showDoor( room ) {
	let door = room.door.o_panel;
	if( !door.parentElement ) self.doorsArea.appendChild( door );
	// room.subscribe();
	// Check door visibility
	room.checkDoorVisibility();
}

function mytable( id ) {
	let t = tables.get( id );
	if( !t ) {
		t = new Table( { numbervisible: !!viewRoom.full.tourid } );
		t.setId( id );
		tables.set( id, t );
		// t.classList.toggle( 'showanimated', !fullmark );
	}
	t.setObsolete( false );
	return t;
}

function hideTable( t ) {
	if( !t ) return;
	delTable( t, true );
	delay( squeezeTables );
}

function squeezeTables() {
	if( checkTables.size ) {
		// First check excess tables list
		main: for( let t of checkTables ) {
			// Check if this table belongs to any user
			for( let tset of userTableSet ) {
				if( tset.has( t ) ) continue main;
			}
			hideTable( t )
		}
		checkTables = new Set;
	}
}

function checkNumericAbsentId( id ) {
	// Если подписка не найдена, может быть есть остаточные признаки того, что это за число
	// Покажем даже с ненайденной подпиской, в надежде вытащить данный по API
	if( !id || !+id ) return;
	// Турниров до 50000 не должно быть, но могут быть команды
	if( +id<100 || ( +id<50000 && !LOCALTEST ) ) {
		if( User.get( 'team_' + id ) ) {
			viewRoom.full.tid = id;
			checkTD();
			return true;
		}
		return;
	}
	if( viewRoom.full.tourid===id ) return true;
	log( 'Showing preview for tournir ' + id ); // + '. viewroom is ' + JSON.stringify( viewRoom ) );
	viewRoom.full.tourid = id;
	// Числовое значение. Может это турнир, попробуем показать таблицу
	setTourLineId( id );
	// self.previewHTML.src = `${CLIENTHOST}/tour/${id}`;
	// self.previewHTML.show();
	checkTD();
	return true;
}

elephSubscribe.addParser( 'table_*', ( o, minor, params ) => {
	let t = tables.get( params.postfix );
	if( !t ) return;			// Not created - no subscription
	// if( !DEBUG )  t.parse( o, minor, params );
	if( minor==='brief' ) {
		switch( t.v ) {
			case true:
				insertTable( t );
				break;
			case false:
				delTable( t );
				break;
		}
	}
} );


function hidetablesanddoors() {
	hidemytables();
}

function obsoleteAll() {
	if( !tables.size ) return;
	tables.forEach( t => t.setObsolete() );
}

function dropSubscriptions( complete ) {
	if( !subscribed ) return;
	// elephCore.toserver( 'UNSUBSCRIBE table_* roomfull_*\n' );
	// elephCore.toserver( 'UNSUBSCRIBE table_*\n' );
	viewRoom?.unsubscribe( true );
	obsoleteAll();
	if( complete ) {
		getDoors().forEach(
			roomid => Room.get( roomid ).unsubscribe( true )
		);
	}
}

// Periodical restore: switching to room, activation of tab, restoring connection
function restoreSubscriptions() {
	if( !showed ) return;
	if( viewRoom ) {
		// Простейший вариант может быть актуальным: очистим столы
		hidemytables();
		viewRoom.subscribe( true );
	}
	subscribed = true;
	elephCore.checkAppUpdate();
}

dispatch( 'connected', restoreSubscriptions );

dispatch( 'disconnected', obsoleteAll );

dispatch( 'onpause', () => {
	//  If subscribed, then stop subscription till resume
	if( !showed ) return;
	// log( 'Stop room subscription' );
	// elephCore.unsubscribe( roomitem );
	log( "DONT STOP SUBSCRIPTION AT PAUSE!" );
} );

// dispatch( 'onresume', () => {
// 	 If subscribed, resume subscription
// restoreSubscriptions();
// } );

/*
dispatch( 'makehelo', ( e ) => {
//            e.helo += ' zone="' + froom + '"';
//        }
	if( chatRoom )
		e.helo += chatRoom.getHelloStr();

	// if( showed )
} );
*/

self.setHolder = holder => holder.appendChild( roomArea );

self.holder = roomArea;

self.onShow = val => {
	log( 'Showing room ' + val );
	showed = val;
	if( val ) {
		delete sessionStorage.view;
		localStorage.lastitem = '';
		if( !viewRoom ) {
			if( openRoomNow ) return goroom( openRoomNow );
			goroom( '' );
			return;
		}
		setViewStyles();
		// sendLocation();
		restoreSubscriptions();
	} else
		dropSubscriptions( true );
};


self.focusChat = () => chatRoom.Input.focus();

self.getName = () => 'room';

export default function createRoom( parentArea, manager ) {
	self.manager = manager;
	parentArea.appendChild( roomArea );
	// Detect first view
	// firstSubsribe();
	return self;
}

self.checkActive = () => {
	if( !viewRoom ) {
		if( elephCore.itemCode )
			fire( 'goroom', elephCore.itemCode );
		return;
	} // firstSubsribe();
	viewRoom.subscribe();		// Recheck subscription is active
};

self.showClubs = () => {
	let count = 0;
	for( let t of elephCore.myTeamsMap ) {
		if( !t[1].includes( 'c' ) ) continue;
		if( clubs.has( t[0] ) ) continue;
		count++;
	}
	let size = count<5? 256 : 128,
		stylesize = count<5? 'min(30vmin,256px)' : 'min(128px,20vmin)'; // ( count<10? 96 : 64 );
	for( let t of elephCore.myTeamsMap ) {
		if( !t[1].includes( 'c' ) ) continue;
		if( clubs.has( t[0] ) ) continue;
		let team = User.getTeam( t[0] );
		let s = fillPlayerHTML( team, {
			size: size,
			classes: 'grayhover column',
			textonpicture: true,
			style: `order: ${team.numericid}`,
			imgoptions: {
				stylesize: stylesize
			}
		} );
		// let s = `<div style='position: relative'>${fillMagicIMG( team.getPicture, {
		// 	size: size
		// })}<span style='position: absolute;bottom: 0;'>${team.getShowName}</span></div>`;
		let el = html( s, self.clubsArea, e => {
			e.stopPropagation();
			setViewRoom( 'team_' + t[0] );
		} );
		clubs.set( t[0], el );
		// self.clubsArea.style.gap = count<5? '1em' : undefined;
		// Subscribe.add( )
	}
	// Удалим ненужные двери
	for( let [ id, el ] of clubs ) {
		if( !elephCore.myTeamsMap.has( id ) ) {
			log( 'Removing disappeared club ' + id );
			// Этой двери уже нет
			el.remove();
			clubs.delete( id );
		}
	}
	// Для гамблера в плотном режиме команд сделаем максимум две строки, чтобы не закрывать залы
	if( !FANTGAMES && !NEOBRIDGE )
		self.clubsArea.classList.toggle( 'highdensity', count>10 );
	// self.clubsArea.classList.toggle( 'hideimageheadtext', self.clubsArea.children.length > 3 );

	// if( viewRoom?.team ) {
	checkTD();
	// }
};

dispatch( 'checkmyteams', () => {
	self.showClubs();
	// Если доступа в текущий клуб нет, перепроверим
	if( viewRoom?.team && !isMyClub )
		updateObjects();
} );

window.addModule( 'Room', createRoom );

dispatch( 'resolveorigin', o => {
	if( o.origin==='club' ) o.object = viewRoom.team;
} );

dispatch( 'firstloaded', id => {
	if( viewRoom?.itemid===id )
		updateObjects();
});

