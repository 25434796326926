import Core from './core.js';

class Head {
	constructor() {
		this.holder = html( `<div class='display_none head visible'
			style='grid-area: head; position: relative;
			z-index: 100'></div>` );
		let left = html( "<div class='flexline nowrap' style='margin-left: 0.3em'></div>", this.holder );
		this.back = construct( '.display_none.room_back.grayhover', left, Core.goBack );
		this.back.style.background = `url( ${IMGPATH}/svg/icons/ic_arrow_back_black_24px.svg ) center/70% no-repeat`;

		// this.imagehead = construct( '.imagehead' );
		// this.icon = construct( '._display_none.room_icon', this.holder );
		// this.title = construct( '.title', this.holder );
		this.imagehead = construct( '.flexline.control', left, this.click.bind( this ) );
		this.connecting = construct( '.track_disconnected {Connecting}...', left );

		// holder.appendChild( Myself() );
		// holder.appendChild( play );

		// parentArea.appendChild( holder );

		dispatch( 'settitle', ttl => {
			log( 'Settitle ' + JSON.stringify( ttl ) );
			this.def = ttl;
			if( typeof ttl==='object' ) {
				let s = '',
					undername = '',
					object = ttl;
				if( ttl.plantime && !ttl.notime ) {
					let ms = checkMS( ttl.plantime ),
						d = new Date( ms ),
						diffdays = Math.round( Math.abs( ms-Date.now() )/(1000*60*60*24) );
					if( diffdays ) s = d.toLocaleDateString();
					// if( diffdays<7 )
					s += (s&&' '||'') + d.toLocaleTimeString().slice( 0, 5 );
					if( ttl.gamephrase )
						s += '. ' + ttl.gamephrase;
					undername = s;
					s += '. ';
				}  else {
					object = User.get( ttl.itemid ) || ttl;
					if( +object.members )
						undername ||= `<span data-name='${object.id}_members'>${object.members}</span> player${+object.members>1&&'s'||''}`;
				}
				this.imagehead.html( fillPlayerHTML( object, {
					photoclick: 'noinfo',
					textstyle: 'max-width: calc( 100vw - 50px - 5em )',
					undername: undername,
					noinfo: true
				} ) );
				s += ttl.titleFull || ttl.title || '';
				this.imagehead.$( '.username' ).classList.add( 'hide_disconnected' );
				// if( DOMAIN.includes( 't.com' ) ) s = '🇺🇦 ' + s;
				// this.title.setContent( s );
				// this.icon.style.backgroundImage = ttl?.icon && `url(${getHiresIcon( ttl.icon, 128 )})` || '';
			} else {
				this.imagehead.setContent( ttl || '' );
				// this.title.setContent( ttl || '' );
				// this.icon.style.backgroundImage = '';
			}
			let backvis = Core.way?.length || false;
			if( ttl?.location && ttl?.location===window.domainData?.root ) backvis = false;
			this.back.makeVisible( backvis );

			let mypan = $( '.myself_panel' );
			if( mypan ) {
				let avatar = !ttl.team && ttl.type!=='team' || !backvis,
					avf = mypan.$( '.avatarfants' );
				avf.makeVisible( avatar );
				avf.classList.toggle( 'hideinportrait', backvis );
				mypan.$( '.allunreadcount' ).makeVisible( !isGuest() && avatar );
				mypan.$( '.clubmine' ).hide();
				mypan.$( '.tourmine' ).hide();
				// mypan.$( '.unlogged' ).makeVisible( window.isGuest() );
				mypan.$( '.unlogged').makeVisible( !UIN );
				mypan.$( '.logged' ).makeVisible( !!UIN );
			}
			checkLandScroll();
		} );
	}

	click( e ) {
		fire( 'headclick', {
			title: this.def,
			event: e
		} );
	}

	setParent( parent ) {
		parent.insertBefore( this.holder, parent.firstChild );
	}
}

export let head = new Head;

addModule( 'Head', head );

function checkLandScroll() {
	let land = $( '#land' );
	if( land ) {
		let vis = land.isVisible();
		log( 'ladnscroll isvis=' + vis );
		head.holder.style.backgroundColor = !land.isVisible() || land.scrollTop===0 ? 'initial' : 'rgba(240,240,240,0.95)';
		if( vis )
			head.holder.style.color = 'black';
		else {
			log( 'debug removing color from holder' );
			head.holder.style.color = '';
		}
	}

}
dispatch( 'landingscroll',  checkLandScroll );
dispatch( 'loggedin',  checkLandScroll );
